import React from 'react';
import config from '../../config';

export default function PageFooter() {
  return (
    <footer id="footer">
      <ul className="copyright">
        <li>&copy; TakeCode 2022</li>
        <li>All rights reserved</li>
        {/*<li>*/}
        {/*  Design: <a href="https://unsplash.com/">Unsplash</a> +{' '}*/}
        {/*  <a href="http://html5up.net">HTML5 UP</a>*/}
        {/*</li>*/}
      </ul>
    </footer>
  );
}
